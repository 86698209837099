// fetch_html_controller.js

import {Controller} from "stimulus";

export default class extends Controller {
  static values = {outputTarget: String, url: String};

  show() {
    const outputTargetValue = this.outputTargetValue;
    fetch(this.urlValue)
      .then((r) => r.text())
      .then((htmlStr) => {
        document.querySelectorAll(`[data-fetch-html-target="${outputTargetValue}"]`).forEach(function (outputTarget) {
          const frag = document.createRange().createContextualFragment(htmlStr);
          outputTarget.innerHTML = '';
          outputTarget.appendChild(frag);
        })
      });
  }
}
