// date_range_controller.js

import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["endOn", "startOn", "output"]
  static values = {url: String};

  show() {
    const outputTargets = this.outputTargets;
    var url = new URL(this.urlValue);
    const endOnVal = this.endOnTarget.value;
    if(endOnVal !== '' ) {
      const dateMoment = moment(endOnVal, "MM/DD/YYYY");
      url.searchParams.append('end_on', dateMoment.format('YYYY-MM-DD'));
    }
    const startOnVal = this.startOnTarget.value;
    if(startOnVal !== '' ) {
      const dateMoment = moment(startOnVal, "MM/DD/YYYY");
      url.searchParams.append('start_on', dateMoment.format('YYYY-MM-DD'));
    }
    fetch(url)
      .then((r) => r.text())
      .then((html) => {
        outputTargets.forEach(function (outputTarget) {
          outputTarget.innerHTML = html;
        })
      });
  }
}
